<script>
export default {
  metaInfo: {
    title: "SIP Subscribers",
  }
};
</script>

<script setup>
import SipSubscribersView from "@/components/dynamic/SipSubscribersView.vue";
</script>

<template>
  <SipSubscribersView/>
</template>

<style scoped>

</style>